import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function HistogramChart(props) {
    //destinationListsList
    console.log(props?.destinationListsList, "9090数据777")
    // const {m2R2Data,} = props.data
    var m2R2Data =[]
    const obj = {
        value: '',
        legendname: "",
        name: "",
        // itemStyle: { color: "#2D5BFB", },
    }
    props?.destinationListsList?.map((item, index) => {
        const obj = {
            value:  item?.volume,
            legendname: item?.transportMode,
            destinationCountry: item?.destinationCountry || item?.sourceLocation,
            name: item?.transportMode,
            // itemStyle: { color: "#2D5BFB", },
        }
        m2R2Data.push(obj)
    })
    const colors = [
        "#2D5BFB",
        "#44EBEA",
        "#43A9EB",
        "#D7D7D7",
        "#A982FF",
        "#8CA3B6",
    ];
    const chartRef2 = useRef()
    // var m2R2Data = [
    //     {
    //         value: 45.6,
    //         legendname: "港口费用",
    //         name: "港口费用",
    //         itemStyle: { color: "#2D5BFB", },
    //     },
    //     {
    //         value: 6.84,
    //         legendname: "堆存费",
    //         name: "堆存费",
    //         itemStyle: { color: "#44EBEA", },
    //     },
    //     {
    //         value: 4.28,
    //         legendname: "货物检验费",
    //         name: "货物检验费",
    //         itemStyle: { color: "#43A9EB", },
    //     },
    //     {
    //         value: 114.97,
    //         legendname: "信用证费用",
    //         name: "信用证费用",
    //         itemStyle: { color: "#D7D7D7", },
    //     },
    //     {
    //         value: 12.56,
    //         legendname: "其他",
    //         name: "其他",
    //         itemStyle: { color: "##A982FF", },
    //     },
    //     {
    //         value: 530.78,
    //         legendname: "关税",
    //         name: "关税",
    //         itemStyle: { color: "#8CA3B6", },
    //     },
    // ];
    var total = 0;
    m2R2Data.forEach(function (value, index, array) {
        total += Number(value.value);
    });
    var coloedes = '#fff'
    // console.log(total, "----111222----")
    var option = {
        title: [
            {
                text: "合计",
                subtext: total?.toFixed(2),
                textStyle: {
                    color: "#fff",
                    fontSize: 12,
                    fontFamily: 'df',
                },
                subtextStyle: {
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: 'df',
                },
                textAlign: "center",
                x: "47.5%",
                y: "24%",
            },
        ],
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'     //${params.name}  ${params.value}
            // formatter: function (params) {
            //     return `<div style="padding: 10px; font-size: 14px; line-height: 20px;">  
            //             <strong>${params.seriesName}</strong><br/>  // 对应 {a}  
            //             ${params.name} : ${params.value} (${params.percent}%)  // 对应 {b} : {c} ({d}%)  
            //         </div>`
            // }, 
        },  
        // legend: [
        //     {
        //         data: ["港口费用",],
        //         formatter: function (name, ticket, callback) {
        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });
        //             let result = '%'
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2) } }` + `{a| ${result} }`

        //         },
        //         orient: "vertical",
        //         left: '58%',//22.5
        //         top: "3%",//"8%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },
        //     {
        //         data: ["堆存费"],
        //         formatter: function (name, ticket, callback) {
        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });
        //             let result = '%'
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2)}}` + `{a| ${result} }`
        //         },
        //         orient: "vertical",
        //         left: '80%',//22.5
        //         top: "3%",//"8%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },
        //     {
        //         data: ["货物检验费"],
        //         formatter: function (name, ticket, callback) {

        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });

        //             let result = '%'
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2)}}` + `{a| ${result} }`

        //         },
        //         orient: "vertical",
        //         left: '58%',//22.5
        //         top: "32%",//"34%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },
        //     {
        //         data: ["信用证费用"],
        //         formatter: function (name, ticket, callback) {
        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });
        //             let result = '%'
        //             // return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2)}}` + `{a| ${result} }`
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) * 100).toFixed(2)}}` + `{a| ${result} }`

        //         },
        //         orient: "vertical",
        //         left: '80%',//22.5
        //         top: "32%",//"34%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },
        //     {
        //         data: ["关税"],
        //         formatter: function (name, ticket, callback) {
        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });
        //             let result = '%'
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2)}}` + `{a| ${result} }`

        //         },
        //         orient: "vertical",
        //         left: '58%',//22.5
        //         top: "59%",//"59%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },
        //     {
        //         data: ["其他"],
        //         formatter: function (name, ticket, callback) {
        //             let v;
        //             m2R2Data.forEach((item) => {
        //                 if (item.name === name) {
        //                     v = item.value;
        //                 }
        //             });
        //             let result = '%'
        //             return '\n' + '\n' + `{a| ${name} }` + '\n' + '\n' + `{{b| ${((v / total) *100 ).toFixed(2)}}` + `{a| ${result} }`

        //         },
        //         orient: "vertical",
        //         left: '80%',//22.5
        //         top: "59%",//"59%"
        //         itemWidth: 14,
        //         itemHeight: 14,
        //         align: "left",
        //         textStyle: {
        //             rich: {
        //                 a: {
        //                     //   align: 'left',
        //                     color: '#839AAA',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //                 b: {
        //                     color: '#fff',
        //                     fontSize: 14,
        //                     fontFamily: 'df'
        //                 },
        //             }
        //         },
        //     },

        // ],
        series: [
            {
                name: "",
                type: "pie",
                center: ["50%", "40%"],
                radius: ["40%", "55%"],
                clockwise: false, //饼图的扇区是否是顺时针排布
                avoidLabelOverlap: false,
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold',
                        fontFamily: 'df'
                    },
                    itemStyle: {
                        //   shadowBlur: 10,
                        //   shadowOffsetX: 0,
                        //   shadowColor: 'rgba(0, 0, 0, 0.5)'
                        fontSize: 12,
                        color: '#839AAA',

                    }
                },
                label: {
                    show: true,
                    position: 'center', // 将标签显示在中心位置
                    normal: {
                        show: true,
                        textStyle: {
                            fontSize: 12,
                            fontFamily: 'df',
                            color: '#839AAA',

                        }
                    },
                    emphasis: {
                        show: true
                    }
                },
                labelLine: {
                    show: true,
                    normal: {
                        length: 7,
                        length2: 5,
                        smooth: true,
                    },
                },
                itemStyle: {
                    normal: {
                        color: function (params) {
                            // var colorList = ['#c23531','#2f4554','#61a0a8','#d48265'];
                            return colors[params.dataIndex];
                        }
                    }
                },
                data: m2R2Data,
            },
        ],
    };






    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef2.current)
        console.log(props?.currentSubsidiary, 'props?.currentSubsidiary');
        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }


    }, [props?.typeTabs, props?.currentSubsidiary, props?.destinationListsList])
    return (
        <div style={{ width: "110%", height: "100%" }} ref={chartRef2}></div>
    );
}
